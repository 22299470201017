import Section from "../../section/Section";
import Title from "../../Title";
import Image from "../../Image";

import detailImage1 from "@images/homepage/accenture/accenture-detail-1.jpg";
import detailImage2 from "@images/homepage/accenture/accenture-detail-2.jpg";

const HomeProgrammeDetailsSectionAccenture = () => (
	<>

		<Section className="pt-md-17 pt-8 mb-10 pb-4 pb-md-17 programme-details-section">
			{/*first part*/}
			<div className="d-flex flex-column flex-md-row">
				<div className="col d-block d-md-none mb-8 mb-md-15 order-1">
					<Title className="text-center font-weight-bold large-h2">
						Learn how to
					</Title>
					<Title className="text-center  font-weight-normal large-h2">
						ask for a pay rise
					</Title>
				</div>
				<div className="col d-flex justify-content-center flex-column order-3 order-md-2">
					<Title className="text-left d-none d-md-block font-weight-bold large-h2">
						Learn how to
					</Title>
					<Title className="text-left d-none d-md-block font-weight-normal large-h2">
						ask for a pay rise
					</Title>
					<div className="pt-10 text-center text-md-left" style={{maxWidth: 410}}>
						<p className="text-gray-dark big-text m-0">
							Do you feel undervalued? Or are you worried about the increasing cost of living?
						</p>
						<p className="text-gray-dark mt-3 big-text mb-0">
							You’ve probably thought about asking for a pay rise. We’ll help you define your skills and value so you ask with confidence.
						</p>
					</div>
				</div>
				<div className="col d-flex align-items-end justify-content-end order-2 order-md-3" style={{lineHeight: 0}}>
					<Image src={detailImage1} width={547}/>
				</div>
			</div>

			{/*second part*/}
			<div className="d-flex flex-column flex-md-row mt-15 pt-md-2 pt-7">
				<div className="col d-flex align-items-start justify-content-start order-2 order-md-1" style={{lineHeight: 0}}>
					<Image src={detailImage2} width={547}/>
				</div>
				<div className="col d-block d-md-none mb-8 mb-md-15 order-1 order-md-2">
					<Title className="text-center font-weight-bold large-h2">
						It could be time
					</Title>
					<Title className="text-center  font-weight-normal large-h2">
						for something new
					</Title>
				</div>
				<div className="col d-flex justify-content-center align-content-end flex-column order-3">
					<div>
						<Title className="text-left d-none d-md-block font-weight-bold large-h2">
							It could be time
						</Title>
						<Title className="text-left d-none d-md-block font-weight-normal large-h2">
							for something new
						</Title>
						<div className="pt-10 text-center text-md-left" style={{maxWidth: 410}}>
							<p className="text-gray-dark big-text mt-0 mb-0">
								Is your job unfulfilling or affecting your self-esteem? Or are you looking to earn more in a new career?
							</p>
							<p className="text-gray-dark mt-3 big-text mb-0">
								Making a change can be daunting but we’re here to get you on a path to doing work you’ll love.
							</p>
						</div>
					</div>
				</div>

			</div>

			{/*<div className="mt-10 pt-4 pt-md-17">*/}
			{/*	<div className="dashed-separator"></div>*/}
			{/*</div>*/}

		</Section>
	</>
);

export default HomeProgrammeDetailsSectionAccenture;
