import Document from "../components/v2/Document";

import heroVideoposter from "@images/homepage/preview.jpg";
import HomepageDocumentAccenture from "../old-pages/homepage/HomepageDocumentAccenture";
import { isAccentureBuild } from "../helpers/utils";
import HomepageDocument from "../components/_pages/v2/home/HomepageDocument";

const Homepage = () => {
	return (
		<Document
			title="Empower Your Team With Award Winning Career Coaching"
			className="page-home-v2"
			ogData={{
				title: "Stay Nimble. Career Coaching for everyone. - Stay Nimble",
				description:
					"Give your team the personalised career support they deserve. Stay Nimble's scalable solutions provide tailored 121 coaching to make upskilling your team stick.",
				image: heroVideoposter,
				url: "/",
			}}
			accentureAccessible={true}
			isNameFirst={true}
			showExitPopup={!isAccentureBuild}
		>
			{isAccentureBuild ? <HomepageDocumentAccenture typeFormId={"PKxW3aoA"} /> : <HomepageDocument />}
		</Document>
	);
};

export default Homepage;
