import Section from "../section/Section";
import SocialEnterpriseLogo from "@images/v2/social-enterprise-logo-only.svg";
import Image from "../Image";

const SocialEnterpriseSection = ({ bgColor = "bg-purple" }) => (
	<Section className={`social-enterprise-section px-4 ${bgColor}`}>
		<div className="mx-auto text-center" style={{ maxWidth: 780 }}>
			<span className="font-size-lg px-6 py-2 bg-white cursor-auto font-weight-bold border border-dark">
				Did you know?
			</span>
			<h2 className="mt-8 pb-4 pb-md-6">We’re a Social Enterprise</h2>

			<p className="font-size-xl m-0 pb-5">
				We believe in a future where career development is accessible to all, so that every person can perform to their
				best, more of the time.
			</p>
			<p className="font-size-xl m-0 pb-5">
				Our smart tech and commitment to reinvest 51% of annual profits into initiatives that help disadvantaged people
				makes this happen.
			</p>
			<p className="font-size-xl m-0">This means we will always offer the most affordable support in the market.</p>
			<p className="font-size-xxxs mt-8 mb-2">Proud to be a Social Enterprise</p>
			<Image src={SocialEnterpriseLogo} alt="Stay Nimble" width={80} height={80} className="py-7 py-md-0" />
		</div>
	</Section>
);

export default SocialEnterpriseSection;
