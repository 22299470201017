import Section from "../../section/Section";
import Title from "../../Title";
import Image from "../../Image";

import recalibrateImage from "@images/homepage/accenture/recalibrate.jpg";

const HomeRecalibrateAccenture = () => (
	<>
		<Section className="pt-7 mt-18 mt-md-0 pb-8 recalibrate-section">
			<div className="d-flex flex-column justify-content-center align-items-center">
				<div
					className="d-flex align-items-center mb-19 mb-md-13 order-2 order-md-1"
					style={{lineHeight: 0}}
				>
					<Image src={recalibrateImage} width={950}/>
				</div>
				<div className="mb-15 order-1 order-md-2" style={{maxWidth: 910}}>
					<Title className="text-center large-h2 text-purple-dark">
						Get recalibrated with our <span className="text-underline">FREE</span> step-by-step Thrive programme
					</Title>
				</div>

				<div className="order-3 d-flex flex-column flex-md-row w-100 justify-content-around">
					<RecalibratePart
						title="Discover your skills"
						text1="Speak one-on-one with your dedicated Stay Nimble coach."
						text2="Identify your strengths, skills, and values to better understand your priorities, what you need most and what’s important to you."
					/>
					<RecalibratePart
						title="Head in the right direction"
						text1="Once we have more clarity on where you’re at we’ll guide you on how to fundamentally change your work situation."
						text2="Creating new opportunities and securing a brighter future."
					/>
					<RecalibratePart
						title="Go boldly!"
						text1="Next, we’ll help you to tell your story with confidence and positivity."
						text2="Your coach will guide you through perfecting your CV, social media profiles and they’ll also sort you out with thorough interview preparation."
					/>
				</div>
			</div>
		</Section>
	</>
);

const RecalibratePart = ({
	title,
	text1,
	text2
												 }) => (
	<div className="mb-12" style={{maxWidth: 310}}>
		<h4 className="mb-5">{title}</h4>
		<p className="text-gray-dark sub-text m-0">
			{text1}
		</p>
		<p className="text-gray-dark sub-text mt-3 mb-0">
			{text2}
		</p>
	</div>
)

export default HomeRecalibrateAccenture;
