import React, { forwardRef, useImperativeHandle, useEffect, useMemo, useRef, useState } from "react";
import { CloseButton } from "./Button";
import { createRandomHash } from "../../../_helpers/_utilities";
import cc from "classcat";
import { noop } from "lodash";
import { useRecoilState } from "recoil";
import { modalCurrentlyOpenState, modalIsOpenState } from "../states/components/ModalStates";
import ModalContainer from "./modal/ModalContainer";
import useResponsive from "../../../_hooks/useResponsive";

const Modal = forwardRef(
	(
		{
			children,
			defaultShowStatus = false,
			hasTopGap = false,
			closeFromBlur = true,
			autoMobileHeight = false,
			className = "",
			containerClassName = "",
			onClose = noop,
			onOpen = noop,
			hideButton = false,
			smallModal = false,
			darkMode = false,
			containerProps = {},
			...props
		},
		ref
	) => {
		const isMobile = useResponsive();
		const modalId = useMemo(() => `modal-${createRandomHash(5)}`, []);

		const [currentModalOpen, setCurrentModalOpen] = useRecoilState(modalCurrentlyOpenState);
		const [showState, setShowState] = useRecoilState(modalIsOpenState(modalId));
		const [showDelayed, setShowDelayed] = useState(showState);
		const [showStatus, setShowStatus] = useState(showState);

		const showTimeout = useRef(null);
		const divRef = useRef(null);

		useEffect(() => {
			if (showState === null) {
				setShowState(defaultShowStatus);
			}
		}, [defaultShowStatus, showState, setShowState]);

		useEffect(() => {
			if (showState) {
				setShowDelayed(true);
				setShowStatus(true);
			} else {
				setShowStatus(false);
				showTimeout.current = setTimeout(() => {
					setShowDelayed(false);
				}, 300);
			}

			return () => {
				if (showTimeout.current) {
					clearTimeout(showTimeout.current);
				}
			};
		}, [showState]);

		useEffect(() => {
			return () => {
				setCurrentModalOpen(null);
			};
		}, [setCurrentModalOpen]);

		useEffect(() => {
			if (currentModalOpen) {
				document.body.classList.add("modal-open");
			} else {
				document.body.classList.remove("modal-open");
			}

			return () => {
				if (!currentModalOpen) {
					document.body.classList.remove("modal-open");
				}
			};
		}, [currentModalOpen]);

		const close = (triggerEvent = true) => {
			return new Promise((resolve) => {
				setShowState(false);
				setCurrentModalOpen(null);
				if (triggerEvent) {
					setTimeout(() => {
						onClose();
						resolve();
					}, 400);
				}
			});
		};

		const open = (triggerEvent = true) => {
			return new Promise((resolve) => {
				setShowState(true);
				setCurrentModalOpen(modalId);
				if (triggerEvent) {
					onOpen();
					setTimeout(() => {
						resolve();
					}, 400);
				}
			});
		};

		useImperativeHandle(ref, () => ({
			close,
			open,
			isOpen: showStatus,
			isClosed: !showStatus,
			el: divRef.current,
			modalId,
		}));

		if (!showDelayed) {
			return null;
		}

		return (
			<div
				className={cc([
					"modal",
					className,
					{
						"hide-modal": !showStatus,
						"modal-small": smallModal,
						"modal-dark": darkMode,
					},
				])}
				data-modal-id={modalId}
				{...props}
				ref={divRef}
			>
				<div
					className="modal-bg"
					onClick={() => {
						if (closeFromBlur) {
							close();
						}
					}}
				/>
				<div
					className={cc([
						"modal-content-container shadow-modal d-flex align-items-stretch",
						containerClassName,
						{
							"flex-grow-0": autoMobileHeight && isMobile,
							"flex-grow-1": !autoMobileHeight && isMobile,
							"align-self-stretch": isMobile,
						},
					])}
					{...containerProps}
				>
					{!hideButton && <CloseButton className="modal-close" onClick={close}/>}
					<div
						className={cc([
							"modal-content d-flex align-items-stretch flex-column flex-grow-1",
							{
								"mt-10": hasTopGap,
								"mt-0": !hasTopGap,
							},
						])}
					>
						<ModalContainer>{children}</ModalContainer>
					</div>
				</div>
			</div>
		);
	}
);

export default Modal;
