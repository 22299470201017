import { forwardRef, cloneElement, useEffect, useRef, useState } from "react";
import ModalHeader from "../../../../src/modules/_common/components/modal/ModalHeader";
import ModalContainer from "../../../../src/modules/_common/components/modal/ModalContainer";
import ModalContent from "../../../../src/modules/_common/components/modal/ModalContent";
import Modal from "../../../../src/modules/_common/components/Modal";
import cc from "classcat";
import { RadioInput } from "../../../../src/modules/_common/components/RadioInput";
import ModalFooter from "../../../../src/modules/_common/components/modal/ModalFooter";
import { PrimaryButton } from "../../../../src/modules/_common/components/Button";
import useAnonymousDataLayer from "../../../../src/_hooks/useAnonymousDataLayer";

const BookingOption = ({
  selected = false,
  className = "",
  children,
  ...props
}) => {
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.checked = selected;
    }
  }, [selected]);

  return (
    <div
      className={cc([
        "rounded p-6 cursor-pointer",
        className,
        { "shadow-lg bg-gray-white": selected, "bg-gray-light": !selected },
      ])}
      style={{
        transition:
          "background-color 300ms ease-in-out, box-shadow 200ms ease-in-out",
      }}
      {...props}
    >
      <div className="d-flex align-items-center">
        <div className="flex-grow-1 pr-4">{children}</div>
        <div>
          <RadioInput ref={inputRef} />
        </div>
      </div>
    </div>
  );
};

const CoachBookingModal = forwardRef(({ coach }, ref) => {
  const [option, setOption] = useState(30);
  const { event } = useAnonymousDataLayer();

  return (
    <Modal ref={ref} containerClassName="w-100">
      <ModalContainer>
        <ModalHeader>
          <div className="d-flex align-items-center justify-content-md-center">
            <div
              className="rounded-circle overflow-hidden"
              style={{ width: 80, height: 80, opacity: 0.7 }}
            >
              {cloneElement(coach.image, {
                className: "crisp-png",
              })}
            </div>
            <div className="ml-4">
              <span className="d-block">{coach.name}</span>
              <span className="d-block text-gray-dark normal-text font-weight-normal">
                {coach.role}
              </span>
            </div>
          </div>
        </ModalHeader>
        <ModalContent>
          <span className="h1 d-block text-center mt-8 mt-md-0">
            How much time would you like to book?
          </span>
          <div className="mt-6 mt-md-10 pb-8 mx-auto" style={{ maxWidth: 295 }}>
            <BookingOption
              selected={option === 30}
              onClick={() => {
                event("Select 30 minutes", "Coaching", "Coaching Interactions");
                setOption(30);
              }}
            >
              <span>30 minutes</span>
              <span className="h2 d-block mt-n1">£30</span>
            </BookingOption>
            <BookingOption
              className="mt-4"
              selected={option === 60}
              onClick={() => {
                event("Select 60 minutes", "Coaching", "Coaching Interactions");
                setOption(60);
              }}
            >
              <span>60 minutes</span>
              <span className="h2 d-block mt-n1">£60</span>
            </BookingOption>
          </div>
        </ModalContent>
        <ModalFooter className="pt-0 pt-md-4 text-center">
          <PrimaryButton
            large={true}
            onClick={() => {
              event(
                `Open ${option} minutes calendly link`,
                "Coaching",
                "Coaching Interactions"
              );
              window.open(coach.calendlyURLs[option]);
              ref.current.close();
            }}
          >
            Book a Call
          </PrimaryButton>
        </ModalFooter>
      </ModalContainer>
    </Modal>
  );
});

export default CoachBookingModal;
