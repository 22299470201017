

import newHeroImage from "@images/homepage/accenture/accenture-hero-new.jpg";
import { PrimaryButton } from "../../../../../src/modules/_common/components/Button";

import socialEnterpriseImage from "@images/homepage/social-enterprise-circle-colorful.png";
import Image from "../../Image";
import useAnonymousDataLayer from "../../../../../src/_hooks/useAnonymousDataLayer";
import { useSetRecoilState } from "recoil";
import { findOutMoreNavigationState } from "../../../states/NavigationStates";

const HomeHeroAccenture = () => {
	const {event} = useAnonymousDataLayer();
	const navigateLearnMore = useSetRecoilState(findOutMoreNavigationState);

	return (
		<div className="container">
			<div className="row accenture-home-hero d-flex flex-column flex-md-row">
				<div className="col-sm d-flex flex-column">
					<div className="mt-0 mt-md-20 text-md-left text-center d-flex flex-column">
						<h1 className="text-purple-dark font-weight-bold">Recalibrate</h1>
						<h1 className="text-purple-dark font-weight-normal">your work life</h1>
						<p className="text-gray-dark font-size-normal hero-subtitle">
							We’re helping over 12,000 people to create new career opportunities and secure their future.
						</p>
					</div>

					<div>
						<PrimaryButton
							large={true}
							className="mb-mb-13 btn-full d-none d-md-block "
							onClick={() => {
								event("Hero Clicked", "Homepage", "Homepage Interactions");
								navigateLearnMore(i => i + 1);
							}}
						>
							Join our free programme
						</PrimaryButton>
					</div>



					<div className="pt-13 d-none d-md-block ">
						<Image
							src={socialEnterpriseImage}
							width={70}
							height={70}
							className="social-enterprise-circular-logo d-none d-md-block "
						/>
					</div>


				</div>
				<div className="col-sm d-flex justify-content-center align-items-center">
					<img src={newHeroImage} className="img-responsive" width={652} height={602}/>
				</div>

				<div className="col-sm d-flex d-md-none pt-12 justify-content-center">
					<PrimaryButton
						large={true}
						className="mb-mb-13"
						onClick={() => {
							event("Hero Clicked", "Homepage", "Homepage Interactions");
							navigateLearnMore(i => i + 1);
						}}
					>
						Join our free programme
					</PrimaryButton>
				</div>
			</div>
		</div>
	);
};
export default HomeHeroAccenture;
