import Section from "../section/Section";
import ReviewSlider from "./trust-pilot-reviews/ReviewSlider";
import cc from "classcat";

import fiveStarsImage from "@images/programmes/digital-marketing/5-stars.png";
import useAnonymousDataLayer from "../../../../src/_hooks/useAnonymousDataLayer";
import ButtonWithArrow from "./ButtonWithArrow";

const ReviewsLogo = () => (
	<div className="d-flex">
		{/*<img id="google-logo" src={trustPilotImage} alt="Trustpilot Reviews" width="180" height="44" />*/}
		<div className="text-center ml-4">
			<div className="d-flex flex-column">
				<img id="stars" src={fiveStarsImage} width="104" height="24" alt="⭐️⭐️⭐️⭐️⭐️" />
				<span className="d-block note">
					Reviews: <strong>4.9</strong>
				</span>
			</div>
		</div>
	</div>
);

const Reviews = ({
	title,
	subtitle,
	numberOfReviews = 24,
	readMore = false,
	className = "",
	hideAffliation = false,
	...props
}) => {
	const { event } = useAnonymousDataLayer();

	return (
		<Section
			{...props}
			className={cc(["trust-pilot-reviews-v2 trust-pilot-alt-style", className])}
			style={{ marginTop: 0 }}
		>
			<div
				className="row flex-column-reverse flex-md-row border-bottom border-dark"
				style={{ position: "relative", zIndex: 2 }}
			>
				<div className="col text-center text-md-left mb-6 mb-md-11">
					<h2 className={cc(["d-block mt-md-4"])}>{title}</h2>
					<p style={{ fontSize: 18 }} className="text-gray-dark font-weight-normal mt-2 mb-0">
						{subtitle}
					</p>
				</div>
				{!hideAffliation && (
					<div className="col-auto d-none d-md-block">
						<div className="text-center trustpilot-banner">
							<div className="px-4 py-5 border border-dark position-relative bg-white">
								<ReviewsLogo />
								<div className="box-behind-main"></div>
							</div>
						</div>
					</div>
				)}
			</div>
			<div className={cc(["row", { "mt-8 mt-md-12": !hideAffliation, "mt-16": hideAffliation }])}>
				<div className="col">
					<ReviewSlider />
				</div>
			</div>
			{readMore && (
				<div className="row mt-6 mt-md-15 mb-8 mb-md-15">
					<div className="col text-center">
						<ButtonWithArrow
							text="Read More"
							onClick={() => {
								event("Reviews click to Trustpilot", "Reviews", "Reviews Interactions");
								window.open("https://uk.trustpilot.com/review/staynimble.co.uk");
							}}
						/>
					</div>
				</div>
			)}
		</Section>
	);
};

export default Reviews;
