import { atom } from "recoil";

export const currentTrustPilotReviewSlideState = atom({
  key: "currentTrustPilotReviewSlide",
  default: 0,
});

export const trustPilotCarouselAnimatingState = atom({
  key: "trustPilotCarouselAnimating",
  default: false,
});
