import cc from "classcat";
import { memo } from "react";

const ModalHeader = ({ children, className = "", container = "h2", ...props }) => {
	const ContainerComponent = container;

	return (
		<ContainerComponent className={cc(["modal-header", className])} style={{ marginTop: -6 }} {...props}>
			{children}
		</ContainerComponent>
	);
};

export default memo(ModalHeader);
