import cc from "classcat";
import { memo } from "react";

const ModalContent = ({ children, className = "", style = {}, innerProps = {}, ...props }) => (
	<div
		className={cc(["flex-grow-1 flex-shrink-1 modal-content-overflow", className])}
		{...props}
		style={{ ...style, overflow: "auto" }}
	>
		<div {...innerProps}>{children}</div>
	</div>
);

export default memo(ModalContent);
