import Section from "./section/Section";
import Title from "./Title";
import CoachGridItem from "./coaches-grid/CoachGridItem";
import Image from "./Image";
import HorizontalDivider from "./HorizontalDivider";

import jenniferImage from "@images/coaches/jennifer.jpg";
import carolineImage from "@images/coaches/caroline.jpg";
import rogerImage from "@images/coaches/roger.jpg";
import jayneImage from "@images/coaches/jayne.jpg";
import leeImage from "@images/coaches/lee.jpg";
import cordeliaImage from "@images/coaches/cordelia.jpg";
import cdiLogo from "@images/pricing/cdi-affliate.png";
import mhfaLogo from "@images/coaches/MHFA-england-logo.png";
import useResponsive from "../../../src/_hooks/useResponsive";

const CoachesGrid = ({
											 title = "Meet our coaches.",
											 blurb = (
												 <p className="text-gray-dark m-0">
													 All Coaches are registered with the Career Development Institute and qualified Mental Health First Aiders.
												 </p>
											 ),
											 allowBooking = false,
											 className = "",
											 ...props
										 }) => {

	const isMobile = useResponsive("sm");

	return (
		<Section className="coach-grid mt-2 mt-md-0" smallContainer={true}>
			<div className="row">
				<div className="col text-center">
					<Title className="mb-4">{title}</Title>
					<div>{blurb}</div>
				</div>
			</div>
			<div className="row mt-8 mt-md-15">
				<div className="col">
					<div className="row">
						<div className="col-12 col-md">
							<CoachGridItem
								allowBooking={allowBooking}
								image={<Image src={jenniferImage} width={227} height={300}/>}
								name="Jennifer Harper"
								role="Head of Coaching"
								text="Career Guidance Adviser. Career Counselling and Coaching. Personal development, including mental health and wellbeing. Editor and Writer."
								regUrl={{
									30: "https://calendly.com/stay-nimble-coaches/stay-nimble-30-minute-session-with-jennifer-paid",
									60: "https://calendly.com/stay-nimble-coaches/stay-nimble-60-minute-session-paid",
								}}
								whoIAm={
									!allowBooking
										? "I am a qualified and registered Career Guidance Adviser who works with people of all age groups who are navigating changing circumstances, are at a crossroads in their life, or..."
										: null
								}
								slug="jennifer"
							/>
						</div>
						<div className="col-auto d-none d-md-block">
							<div className="v-divider dashed text-passthrough-mid"/>
						</div>
						<div className="col px-0 d-md-none">
							<HorizontalDivider dashed={true}/>
						</div>
						<div className="col-12 col-md">
							<CoachGridItem
								allowBooking={allowBooking}
								image={<Image src={carolineImage} width={227} height={300}/>}
								name="Caroline Jones"
								role="Career Coach"
								text="Career, guidance and outplacement coach with 20+ years' experience running own award-winning recruitment business."
								regUrl={{
									30: "https://calendly.com/stay-nimble-coaches/stay-nimble-30-minute-session-with-caroline",
									60: "https://calendly.com/stay-nimble-coaches/stay-nimble-60-minute-session-with-caroline-paid",
								}}
								whoIAm={
									!allowBooking
										? "I am a member of the Career Development Institute, qualified to level 6 Career Coach. Coupled with a background in the career coaching industry and previously over..."
										: null
								}
								slug="caroline"
							/>
						</div>
					</div>
					<HorizontalDivider dashed={true} className="mx-0"/>
					<div className="row">
						<div className="col-12 col-md">
							<CoachGridItem
								allowBooking={allowBooking}
								image={<Image src={rogerImage} width={227} height={300}/>}
								name="Roger Martin"
								role="Career Coach"
								text="Registered Career Development Specialist supporting clients with Career Advice, Career Progression, Job Search and raising Social Mobility."
								regUrl={{
									30: "https://calendly.com/stay-nimble-coaches/stay-nimble-30-minute-session-with-roger-paid",
									60: "https://calendly.com/stay-nimble-coaches/stay-nimble-60-minute-session-with-roger-paid",
								}}
								whoIAm={
									!allowBooking
										? "I have extensive experience of helping people find the area of work that motivates and excites them - a career they can develop and call their own. I have experienced many of the same..."
										: null
								}
								slug="roger"
							/>
						</div>
						<div className="col-auto d-none d-md-block">
							<div className="v-divider dashed text-passthrough-mid"/>
						</div>
						<div className="col-12 px-0 d-md-none">
							<HorizontalDivider dashed={true}/>
						</div>
						<div className="col-12 col-md">
							<CoachGridItem
								allowBooking={allowBooking}
								image={<Image src={jayneImage} width={227} height={300}/>}
								name="Jayne Saul-Paterson"
								role="Career Coach"
								text="Coach and Facilitator for 20 years - Career Development, Leadership, Resilience & Inclusion. Performance Coach and registered career guidance professional."
								regUrl={{
									30: "https://calendly.com/stay-nimble-coaches/stay-nimble-30-minute-session-with-jayne-paid",
									60: "https://calendly.com/stay-nimble-coaches/stay-nimble-60-minute-session-with-jayne-paid",
								}}
								whoIAm={
									!allowBooking
										? "I am a qualified performance and career coach working in teaching, training, and career consultancy. I have coached hundreds of managers..."
										: null
								}
								slug="jayne"
							/>
						</div>
					</div>
					<HorizontalDivider dashed={true} className="mx-0"/>
					<div className="row">
						<div className="col-12 col-md">
							<CoachGridItem
								allowBooking={allowBooking}
								image={<Image src={leeImage} width={227} height={300}/>}
								name="Lee Barnes"
								role="Career Coach"
								text="Registered Career Development Institute professional supporting people with Career Advice, Career Progression and Job Search. A specialism in the learning and development, and education."
								regUrl={{
									30: "https://calendly.com/stay-nimble-coaches/stay-nimble-30-min-video-session-with-lee-paid",
									60: "https://calendly.com/stay-nimble-coaches/stay-nimble-60-min-video-session-with-lee-paid",
								}}
								whoIAm={
									!allowBooking
										? "I am a qualified career coach and guidance professional, with experience of working with a diverse range of clients across all age groups..."
										: null
								}
								slug="lee"
							/>
						</div>
						<div className="col-auto d-none d-md-block">
							<div className="v-divider dashed text-passthrough-mid"/>
						</div>
						<div className="col-12 px-0 d-md-none">
							<HorizontalDivider dashed={true}/>
						</div>
						<div className="col-12 col-md">
							<CoachGridItem
								allowBooking={allowBooking}
								image={<Image src={cordeliaImage} width={227} height={300}/>}
								name="Cordelia Wise"
								role="Career Coach"
								text="Registered Career Development Institute professional supporting people with Career Advice, Career Progression and Job Search. A specialism in the third sector in mental health, homelessness and community education."
								regUrl={{
									30: "https://calendly.com/stay-nimble-coaches/stay-nimble-30-min-video-session-with-cordelia",
									60: "https://calendly.com/stay-nimble-coaches/stay-nimble-60-min-video-session-with-cordelia",
								}}
								whoIAm={
									!allowBooking
										? "I am a qualified career coach and registered with the Career Development Institute.  I have worked with a wide range of clients over the..."
										: null
								}
								slug="cordelia"
							/>
						</div>
					</div>
					<HorizontalDivider dashed={true} className="mx-0"/>
					<div className="row">
						<div className="col-12 col-md">
							<div className="row align-items-center justify-content-center justify-content-md-between">
								<div className="col-12 col-sm-auto text-center text-sm-left">
									<Image
										src={cdiLogo}
										width={175}
										height={132}
										alt="CDI Affliated Organisation"
									/>
									<p className="mt-8 mt-md-10 mb-0 mx-auto text-left" style={{maxWidth: isMobile ? "100%" : 190}}>
										<a
											href="https://www.thecdi.net/Home"
											className="link"
											target="_blank"
											rel="noreferrer"
										>
											The UK Register of Career Development Professionals
										</a>{" "}
										is the single national point of reference for ensuring and
										promoting the professional status of career development
										practitioners across the whole sector in the UK.
									</p>
								</div>
								<div className="col-12 col-sm-auto text-center text-sm-left mt-15 mt-sm-0">
									<Image
										src={mhfaLogo}
										width={132}
										height={132}
										alt="CDI Affliated Organisation"
									/>
									<p className="mt-8 mt-md-10 mb-0 mx-auto text-left" style={{maxWidth: isMobile ? "100%" : 190}}>
										<a
											href="https://mhfaengland.org/"
											className="link"
											target="_blank"
											rel="nofollow"
										>
											Mental Health First Aid
										</a>{" "}
										are the largest provider training in the UK and Europe, with over ten years of
										experience providing an in-depth understanding of mental health and the factors that can affect
										wellbeing.
									</p>
								</div>
							</div>
						</div>
						<div className="col-auto d-none d-md-block">
							<div className="v-divider dashed text-passthrough-mid"/>
						</div>
						<div className="col-12 px-0 d-md-none">
							<HorizontalDivider dashed={true}/>
						</div>
						<div className="col-12 col-md">
						</div>


					</div>
				</div>
			</div>
		</Section>
	);
};

export default CoachesGrid;
