import FullWidthSection from "./section/FullWidthSection";
import Icon from "./Icon";
import { PrimaryButton } from "../../../src/modules/_common/components/Button";
import cc from "classcat";
import useAnonymousDataLayer from "../../../src/_hooks/useAnonymousDataLayer";

const AverageCoachRating = ({className = "", showButton = true}) => {
	const {event} = useAnonymousDataLayer();

	return (
		<FullWidthSection
			bgColor="gray-light"
			className={cc(["text-purple-semi-dark", className])}
		>
			<div className="row pt-4 pb-6 pb-md-4">
				<div className="col">
					<div className="d-flex flex-column flex-md-row align-items-center justify-content-center">
						<div className="d-flex align-items-center">
              <span
								style={{fontSize: 32}}
								className="font-weight-bold text-purple-semi-dark"
							>
                4.9/5
              </span>
							<Icon
								name="favourite-careers"
								color="purple-semi-dark"
								height={52}
								width={52}
							/>
						</div>
						<span className="d-block ml-md-2 mr-md-8 mt-1 mt-md-0">
              Average rating for coaching sessions.
            </span>
						{showButton === true && (
							<PrimaryButton
								className="mt-6 mt-md-0 py-4"
								onClick={() => {
									event(
										"Book free 30 min session",
										"coaching",
										"Coaching interaction"
									);
									window.open(`${process.env.GATSBY_GET_ACCESS_BUTTON}`);
								}}
							>
								Get Access
							</PrimaryButton>
						)}
					</div>
				</div>
			</div>
		</FullWidthSection>
	);
};

export default AverageCoachRating;
