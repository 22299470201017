import FullWidthSection from "../section/FullWidthSection";
import Icon from "../Icon";
import { Button } from "../../../../src/modules/_common/components/Button";
import cc from "classcat";
import useAnonymousDataLayer from "../../../../src/_hooks/useAnonymousDataLayer";
import useTypeForm from "../../hooks/useTypeForm";

const AverageCoachRating = ({ className = "", isConsultation = true }) => {
	const { event } = useAnonymousDataLayer();
	const freeConsultationForm = useTypeForm("R6iu6OZO");

	return (
		<FullWidthSection bgColor="mid-green" className={cc(["text-black", className])}>
			<div className="row pt-8 pb-8 pb-md-12 pt-md-12">
				<div className="col">
					<div className="d-flex flex-column flex-md-row align-items-center justify-content-center">
						<div className="d-flex align-items-center">
							<span style={{ fontSize: 32 }} className="font-weight-bold text-black pr-2">
								4.9/5
							</span>
							<Icon name="trust-pilot-star" color="#47D983" height={45} width={45} />
						</div>
						<span className="d-block ml-md-2 mr-md-8 mt-1 mt-md-0 font-size-lg">
							Average rating for coaching sessions.
						</span>
						{isConsultation === true && (
							<Button
								className="mt-6 mt-md-0 py-4 btn-mid-green border border-dark font-size-lg"
								onClick={() => {
									event("Book free 30 min session", "coaching", "Coaching interaction");
									freeConsultationForm.open();
								}}
							>
								Book a FREE consultation
							</Button>
						)}
						{isConsultation === false && (
							<Button
								className="mt-6 mt-md-0 py-4 btn-mid-green border border-dark font-size-lg"
								onClick={() => {
									event("Book free 30 min session", "coaching", "Coaching interaction");
									window.open(`${process.env.GATSBY_GET_ACCESS_BUTTON}`);
								}}
							>
								Sign up today!
							</Button>
						)}
					</div>
				</div>
			</div>
		</FullWidthSection>
	);
};

export default AverageCoachRating;
