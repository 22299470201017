import FullWidthSection from "./section/FullWidthSection";
import Title from "./Title";

import useResponsive from "../../../src/_hooks/useResponsive";

const OurPartnershipWithAccenture = () => {
	const isMobile = useResponsive();
	return (
		<FullWidthSection
			bgColor="purple-xlight-mid"
			className="py-8 py-md-15"
		>
			<div className="row">
				<div className={`col ${isMobile ? "text-center" : "text-left"}`}>
					<Title className={`text-purple-semi-dark h1 ${isMobile ? "text-center" : "text-left"}`}>
						Our partnership with Accenture
					</Title>
					<p
						className="text-purple-mid mt-6 mb-4 mx-auto h3 font-weight-normal"
					>
						Stay Nimble has worked in partnership with Accenture since 2019 to ensure that as many people as possible have access to quality career planning programmes and coaching.
					</p>
					<p
						className="text-purple-mid mb-4 mx-auto h3 font-weight-normal"
					>
						We’ve collaborated with Accenture’s experts to co-create the technology that powers our award-winning platform, and with Accenture’s funding we are able to provide free access to our coaching and career pathway programmes for those who need it most. To date we have together supported thousands of people find new careers.
					</p>
					<p
						className="text-purple-mid mb-6 mx-auto h3 font-weight-normal"
					>
						Stay Nimble is a proud partner within Accenture’s global Skills to Succeed programme, which aims to support people to gain skills and access employment and entrepreneurship opportunities that help them thrive in the digital economy. You can read more about Accenture’s Skills to Succeed programmes
						{" "}<a
							href="https://www.accenture.com/gb-en/about/corporate-citizenship/skills-succeed"
							className="h3 font-weight-normal text-purple-light"
							target="_blank"
						>
							here
						</a>.
					</p>
				</div>
			</div>
		</FullWidthSection>
)};

export default OurPartnershipWithAccenture;
