import cc from "classcat";
import { memo } from "react";

const ModalContainer = ({ children, className = "", style = {}, ...props }) => (
	<div
		className={cc(["d-flex flex-grow-1 flex-column modal-container", className])}
		style={{ ...style, height: "100%" }}
		{...props}
	>
		{children}
	</div>
);
export default memo(ModalContainer);
