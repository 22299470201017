import React, { Children, cloneElement, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import cc from "classcat";
import { isEmpty, noop } from "lodash";
import { waitForDOMElReady } from "../../../_helpers/_dom";
import produce from "immer";

export const RadioInput = React.forwardRef(
	(
		{
			label = null,
			value = "",
			checked = false,
			onChange = noop,
			onClick = noop,
			onCheck = noop,
			onUncheck = noop,
			inputProps = {},
			disabled = false,
			className = "",
			labelProps = {},
			...props
		},
		ref
	) => {
		const [internalChecked, setInternalChecked] = useState(checked);

		useEffect(() => {
			setInternalChecked(checked);
		}, [checked]);

		return (
			<label className="radio-container" {...labelProps}>
				<input
					type="radio"
					className="radio-input"
					onChange={(e) => {
						const isChecked = e.target.checked;
						if (isChecked) {
							setInternalChecked(true);
						} else {
							setInternalChecked(false);
						}
						onChange(isChecked, value);
					}}
					checked={internalChecked}
					disabled={disabled}
					value={value}
					ref={ref}
					{...inputProps}
				/>
				<span className="radio-wrapper">
					<span className={cc(["radio-display radio-rounded", className])} {...props} />
					{label && <span className="radio-label">{label}</span>}
				</span>
			</label>
		);
	}
);

export const RadioGroup = React.forwardRef(
	(
		{
			name = "radio-group",
			onChange = noop,
			defaultSelected = null,
			className = "",
			itemClassName = null,
			stack = "vertical",
			children,
			...props
		},
		ref
	) => {
		const [value, setValue] = useState(defaultSelected);
		const childRefs = useRef([]);

		const changeValue = (isChecked, value) => {
			if (isChecked) {
				setValue(value);
				onChange(value);
			}
		};

		const setCheckedVal = useCallback(
			async (value) => {
				await waitForDOMElReady(`input[name='${name}']`);
				childRefs.current.forEach((el) => {
					el.checked = el.value.toString() === value.toString();
				});
			},
			[name]
		);

		useImperativeHandle(ref, () => ({
			setValue: setCheckedVal,
		}));

		return (
			<ul
				className={cc([
					"radio-group",
					className,
					{
						"radio-group-vertical": stack !== "horizontal",
						"radio-group-horizontal": stack === "horizontal",
					},
				])}
				{...props}
			>
				{Children.map(children, (child, index) => (
					<li key={index} className={itemClassName}>
						{cloneElement(child, {
							checked: value === child.props.value,
							onChange: changeValue,
							ref: (el) => {
								if (el) {
									childRefs.current = produce(childRefs.current, (childRefs) => {
										if (isEmpty(childRefs.filter((ref) => ref.value === el.value))) {
											return [...childRefs, el];
										}
										return childRefs;
									});
									el.setAttribute("name", name);
								}
							},
						})}
					</li>
				))}
			</ul>
		);
	}
);
