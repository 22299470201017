import { useRecoilValue } from "recoil";
import { findOutMoreNavigationState } from "../../states/NavigationStates";
import { useEffect } from "react";
import useProgrammeEvent from "../../hooks/useProgrammeEvent";
import { isFunction, noop } from "lodash";

const LearnMoreNavigator = ({
  domQuery = ".programme-quick-details",
  eventCallback = noop,
}) => {
  const findOutMoreNavigationTriggers = useRecoilValue(
    findOutMoreNavigationState
  );

  const event = useProgrammeEvent();

  useEffect(() => {
    if (findOutMoreNavigationTriggers > 0) {
      const section = document.querySelector(domQuery);
      if (isFunction(eventCallback)) {
        eventCallback();
      } else {
        event("Go to more information");
      }
      if (section) {
        const headerOffset = 85;
        const elementPosition = section.offsetTop;
        const offsetPosition = elementPosition - headerOffset;
        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }
  }, [findOutMoreNavigationTriggers, domQuery]);

  return null;
};

export default LearnMoreNavigator;
