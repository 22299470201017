import reviews from "./reviews.json";
import Review from "./Review";
import { useCallback, useEffect, useRef } from "react";
import { useRecoilState } from "recoil";
import { currentTrustPilotReviewSlideState, trustPilotCarouselAnimatingState } from "../../../states/TrustPilotStates";
import cc from "classcat";
import { sleep } from "../../../../../src/_helpers/_utilities";
import useAnonymousDataLayer from "../../../../../src/_hooks/useAnonymousDataLayer";
import Icon from "../../Icon";

const ReviewSlider = () => {
	const { event } = useAnonymousDataLayer();

	const [currentSlide, setCurrentSlide] = useRecoilState(currentTrustPilotReviewSlideState);

	const [animating, setAnimating] = useRecoilState(trustPilotCarouselAnimatingState);

	const sliderContainerRef = useRef();
	const animationTimeout = useRef(null);

	const resizeSlider = useCallback(() => {
		if (!animating) {
			const slider = sliderContainerRef.current;
			const firstSlide = slider.querySelector("li.review");
			slider.style.height = `${firstSlide.clientHeight}px`;
		}
	}, [animating]);

	useEffect(() => {
		if (typeof window !== "undefined") {
			window.addEventListener("resize", resizeSlider);
			resizeSlider();
		}

		return () => {
			if (typeof window !== "undefined") {
				window.removeEventListener("resize", resizeSlider);
			}
		};
	}, []);

	useEffect(() => {
		return () => {
			if (animationTimeout.current) {
				clearTimeout(animationTimeout.current);
			}
		};
	}, []);

	const goToSlide = async (newIndex) => {
		if (newIndex === currentSlide || animating) {
			return;
		}
		const slider = sliderContainerRef.current;
		if (slider) {
			const slides = Array.from(slider.querySelectorAll(".review"));
			const curSlide = slides.find((slide) => slide.getAttribute("data-index").toString() === currentSlide.toString());
			const newSlide = slides.find((slide) => slide.getAttribute("data-index").toString() === newIndex.toString());

			setAnimating(true);

			// Direction of slide
			const direction = currentSlide < newIndex ? "left" : "right";

			const newSlideClone = newSlide.cloneNode(true);
			const height = newSlide.clientHeight;
			slider.removeChild(newSlide);
			slider.style.height = `${height}px`;

			if (direction === "left") {
				await sleep(50);
				slider.insertBefore(newSlideClone, curSlide.nextSibling);
				curSlide.style.marginLeft = "-100%";
				animationTimeout.current = setTimeout(() => {
					const curSlideClone = curSlide.cloneNode(true);
					curSlideClone.style.marginLeft = "0%";
					slider.removeChild(curSlide);
					slider.appendChild(curSlideClone);
					setAnimating(false);
				}, 500);
			} else {
				newSlideClone.style.marginLeft = "-100%";
				slider.insertBefore(newSlideClone, curSlide);
				await sleep(50);
				newSlideClone.style.marginLeft = "0%";
				animationTimeout.current = setTimeout(() => {
					setAnimating(false);
				}, 500);
			}
		}
		setCurrentSlide(newIndex);
	};

	const goPrevious = () => {
		event("Clicked Previous Review", "Reviews", "Reviews Interactions");
		goToSlide(currentSlide === 0 ? reviews.length - 1 : currentSlide - 1);
	};

	const goNext = () => {
		event("Clicked Next Review", "Reviews", "Reviews Interactions");
		goToSlide(currentSlide === reviews.length - 1 ? 0 : currentSlide + 1);
	};

	return (
		<>
			<div className="row">
				<div className="col">
					<ul className="reviews-carousel list-unstyled mx-0 mb-0 mt-8 mt-md-n4 p-0" ref={sliderContainerRef}>
						{reviews.map((review, index) => (
							<Review key={index} review={review} index={index} />
						))}
					</ul>
				</div>
			</div>
			<div className="row justify-content-center mt-14 mt-md-10 flex-nowrap">
				<div className="col-auto pr-0">
					<span className="link review-prev-btn" onClick={goPrevious}>
						<Icon name="left-arrow-small" color="white" height={14} width={13} />
					</span>
				</div>
				<div className="col col-md-auto px-1 px-sm-4 d-flex align-items-center review-dots-container">
					<ul className="review-dots list-unstyled flex-grow-1 p-0 m-0 text-center">
						{reviews.map((_, index) => (
							<li
								className={cc([
									"review-dot",
									{
										"bg-gray-semi-light": currentSlide !== index,
										"bg-mid-green": currentSlide === index,
									},
								])}
								key={index}
								onClick={() => {
									event("Clicked Paginated Review", "Reviews", "Reviews Interactions");
									goToSlide(index);
								}}
							/>
						))}
					</ul>
				</div>
				<div className="col-auto pl-0">
					<span className="link review-next-btn" onClick={goNext}>
						<Icon name="right-arrow-small" color="white" height={14} width={13} />
					</span>
				</div>
			</div>
		</>
	);
};

export default ReviewSlider;
