import HomeHero from "./HomeHero";
import Reviews from "../../../v2/Reviews";
import AverageCoachRating from "../../../v2/AverageCoachRating";

import AwardWinning from "../../../v2/AwardWinning";

import useAnonymousDataLayer from "../../../../../../src/_hooks/useAnonymousDataLayer";
import CareerDevelopmentSection from "./CareerDevelopmentSection";
import AffordableSection from "./AffordableSection";
import SocialEnterpriseSection from "../../../v2/SocialEnterpriseSection";
import AmbitiousSection from "./AmbitiousSection";
import SparkSection from "./SparkSection";
import HomeLatestArticles from "./HomeLatestArticles";
import MotionSection from "../../../v2/MotionSection";
import FeaturedIn from "../../../v2/featured-in/FeaturedIn";

const HomepageDocument = () => {
	const { event } = useAnonymousDataLayer();

	return (
		<>
			<HomeHero />
			<FeaturedIn />
			<CareerDevelopmentSection />

			<AwardWinning />
			<AverageCoachRating />
			<AffordableSection />
			<SocialEnterpriseSection />
			<AmbitiousSection />
			<Reviews
				readMore={true}
				className="pt-md-20 pt-10"
				title="Join organisations like yours …"
				subtitle="Rediscovering their ambitions and taking steps to happiness and contentment."
			/>
			<SparkSection />
			<HomeLatestArticles />
			<MotionSection />
			{/*<GetStarted />*/}
		</>
	);
};

export default HomepageDocument;
