import Section from "./section/Section";
import ReviewSlider from "./trust-pilot-reviews/ReviewSlider";
import { SecondaryButton } from "../../../src/modules/_common/components/Button";
import cc from "classcat";

import fiveStarsImage from "@images/programmes/digital-marketing/5-stars.png";
import googleLogoImage from "@images/programmes/digital-marketing/google.png";
import useAnonymousDataLayer from "../../../src/_hooks/useAnonymousDataLayer";

const ReviewsLogo = () => (
	<div className="row">
		<div className="col-12 text-center">
			<img
				id="google-logo"
				src={googleLogoImage}
				alt="Google Reviews"
				width="104"
				height="36"
			/>
		</div>
		<div className="col-12 text-center mt-2">
			<div className="d-inline-flex align-items-center">
        <span className="d-block note mr-2">
          Reviews: <strong>5.0</strong>
        </span>
				<img
					id="stars"
					src={fiveStarsImage}
					width="104"
					height="24"
					alt="⭐️⭐️⭐️⭐️⭐️"
				/>
			</div>
		</div>
	</div>
);

const Reviews = ({
	numberOfReviews = 24,
	readMore = false,
	className = "",
	hideAffliation = false,
	...props
}) => {
	const {event} = useAnonymousDataLayer();

	return (
		<Section
			{...props}
			className={cc(["trust-pilot-reviews trust-pilot-alt-style", className])}
			style={{marginTop: 0}}
		>
			<div
				className="row flex-column-reverse flex-md-row"
				style={{position: "relative", zIndex: 2}}
			>
				<div className="col text-center text-md-left">
					<h2 className={cc(["d-block h1 mt-md-4"])}>Join thousands of people</h2>
					<p style={{fontSize: 18}} className="text-gray-dark font-weight-normal mt-2 mb-0">Believing in themselves and taking steps to happiness and contentment.</p>
				</div>
				{!hideAffliation && (
					<div className="col-auto">
						<div
							className="shadow-lg px-8 pb-8 pt-15 text-center mx-auto mx-md-0 trustpilot-banner"
							style={{borderRadius: "0 0 16px 16px", maxWidth: 225}}
						>
							<div className="px-8">
								<ReviewsLogo/>
							</div>
						</div>
					</div>
				)}
			</div>
			<div className={cc(["row", {"mt-md-n16": !hideAffliation, "mt-16": hideAffliation}])}>
				<div className="col">
					<ReviewSlider/>
				</div>
			</div>
			{readMore && (
				<div className="row mt-6 mt-md-15 mb-8 mb-md-15">
					<div className="col text-center">
						<SecondaryButton
							large={true}
							onClick={() => {
								event(
									"Reviews click to Trustpilot",
									"Reviews",
									"Reviews Interactions"
								);
								window.open(
									"https://uk.trustpilot.com/review/staynimble.co.uk"
								);
							}}
						>
							Read More Reviews
						</SecondaryButton>
					</div>
				</div>
			)}
		</Section>
	);
};

export default Reviews;
