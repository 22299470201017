import Hero from "../../../v2/hero/Hero";

import heroImage from "@images/v2/home/hero-2.jpg";
import useAnonymousDataLayer from "../../../../../../src/_hooks/useAnonymousDataLayer";

const HomeHero = () => {
	const { event } = useAnonymousDataLayer();

	return (
		<Hero
			heroImage={heroImage}
			title="Making Career Development accessible for all"
			subtitle="Set your team’s future in motion with human-centred, highly qualified one-on-one coaching."
		>
			<div className="d-flex flex-column flex-md-row">
				<a
					href="/for-organisations"
					className="btn btn-cornered btn-black btn-hover-light-green font-size-lg text-center mr-2 mb-4 mb-md-0"
				>
					For Organisations
				</a>
				<a href="/for-individuals" className="btn btn-cornered btn-white btn-hover-purple font-size-lg text-center">
					For Individuals
				</a>
			</div>
		</Hero>
	);
};
export default HomeHero;
