import useResponsive from "../../../../../src/_hooks/useResponsive";
import Title from "../../Title";
import Image from "../../Image";
import checkImage from "@images/homepage/accenture/check.png";
import FullWidthSection from "../../section/FullWidthSection";
import {PrimaryButton} from "../../../../../src/modules/_common/components/Button";
import useTypeForm from "../../../hooks/useTypeForm";

const HomeCostAccenture = ({typeFormId}) => {
	const isMobile = useResponsive();
	const getStartedForm = useTypeForm(typeFormId);
	return (
		<FullWidthSection
			bgColor="purple-xlight-mid"
			className="py-10 px-5 cost-section text-purpler-semi-dark"
		>
			<div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-between">
				<div className="">
					<h3 className="mb-4 pl-2">
						Cost:
					</h3>
					<div className="h-divider dashed mb-7"/>

					<Title className={`text-purple-semi-dark h1 mb-15 mb-md-6 ${isMobile ? "text-center" : "text-left"}`}>
						<span style={{textDecoration: "line-through"}}>£480.00{"  "}</span>Free
					</Title>

					<h4 className="mb-3">Delivery</h4>
					<p className="my-0 font-weight-normal">
						Fully online and remote learning and video calls

					</p>
					<h4 className="mb-3 mt-7">Duration</h4>
					<p className="my-0 font-weight-normal">
						Self-paced (12 weeks if you dedicate 3 hrs per week).
					</p>

					<PrimaryButton
						large={true}
						className="mt-14 d-none d-md-block "
						onClick={() => getStartedForm.open()}
					>
						Enrol now
					</PrimaryButton>
				</div>

				<div className="">
					<h3 className="mb-4 pl-2 mt-19 mt-md-0">
						What’s included:
					</h3>
					<div className="h-divider dashed mb-7"/>

					<div className="d-flex mt-14 mt-md-10">
						<div className="mr-3">
							<Image src={checkImage} width={35} height={35}/>
						</div>
						<div>
							<h4>Six hours one-on-one</h4>
							<p className="mt-3 font-weight-normal" style={{maxWidth: 450}}>
								You’ll get six hours of one-on-one dedicated support from our friendly career coaches.
							</p>
						</div>
					</div>

					<div className="d-flex mt-14 mt-md-10">
						<div className="mr-3">
							<Image src={checkImage} width={35} height={35}/>
						</div>
						<div>
							<h4>Action Plan</h4>
							<p className="mt-3 font-weight-normal" style={{maxWidth: 450}}>
								Our proven Thrive action plan that’s assured to lead you on a path to work life transformation.
							</p>
						</div>
					</div>

					<div className="d-flex mt-14 mt-md-10">
						<div className="mr-3">
							<Image src={checkImage} width={35} height={35}/>
						</div>
						<div>
							<h4>Lifetime access</h4>
							<p className="mt-3 font-weight-normal" style={{maxWidth: 450}}>
								All Thrive programmes come with free lifetime access to the Stay Nimble platform.
							</p>
						</div>
					</div>

				</div>
			</div>
		</FullWidthSection>
)};

export default HomeCostAccenture;
