import FullWidthSection from "../../../section/FullWidthSection";
import Title from "../../../Title";
import Image from "../../../Image";
import HomepageCareerBuildingAccentureTile from "./HomepageCareerBuildingAccentureTile";

import thriveLogo from "@images/homepage/accenture/thrive-logo.png";
import DMLogo from "@images/homepage/accenture/dm-logo.png";

const HomeOurCareerBuildingPlatformAccenture = () => (
	<FullWidthSection className="py-8 py-md-20">
		<div className="row">
			<div className="col">
				<Title>Our Career building programmes.</Title>
				<p className="mt-4 mb-0 big-text text-passthrough text-center">
					Our programmes are designed to help you achieve your work-related goals.
				</p>
			</div>
		</div>
		<div className="row mt-10">
			<div className="col">
				<div
					style={{ maxWidth: 895 }}
					className="mx-auto d-flex align-items-sm-stretch align-items-center justify-content-between flex-column flex-sm-row "
				>
					<HomepageCareerBuildingAccentureTile
						image={<Image src={thriveLogo} width={160} height={115} />}
						title="Thrive"
						description="Connect your work with who you are and learn how to manage life choices and changes."
						bulletPoints={["Identify a career path.", "Earn what you deserve.", "Build your confidence."]}
						url="/thrive/"
					/>
					<HomepageCareerBuildingAccentureTile
						image={<Image src={DMLogo} width={160} height={115} />}
						title="Launchpad"
						description="Explore and launch your career in Digital Marketing."
						className="mt-6 mt-sm-0"
						bulletPoints={["Explore Digital Marketing.", "Get certified.", "Supported job search."]}
						url="/launchpad/digital-marketing/"
					/>
				</div>
			</div>
		</div>
	</FullWidthSection>
);

export default HomeOurCareerBuildingPlatformAccenture;
