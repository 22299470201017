import useResponsive from "../../../../../src/_hooks/useResponsive";
import FullWidthSection from "../../section/FullWidthSection";
import {PrimaryButton} from "../../../../../src/modules/_common/components/Button";
import useTypeForm from "../../../hooks/useTypeForm";

const HomeJoinAccenture = ({typeFormId}) => {
	const getStartedForm = useTypeForm(typeFormId);
	return (
		<FullWidthSection
			className="accenture-join-section hero position-relative d-flex flex-column align-items-stretch"
			containerClassName="h-100"
		>
			<div className="hero-video">
				<div className="image-bg-overlay" />
			</div>
			<div style={{zIndex: 4}} className="pb-8 position-relative d-flex flex-column justify-content-end align-items-center h-100">
				<div className="">
					<h2 className="text-gray-white text-center font-weight-bold large-h2 mb-5 mb-md-12">
						Join the Thrive programme today
					</h2>
				</div>

				<p className="font-weight-bold text-gray-white mt-0 mb-10 mb-md-12" style={{maxWidth: 375, fontSize: 22}}>
					Start the programme today and create new career opportunities.
				</p>

				<PrimaryButton
					large={true}
					className="mb-0 mb-md-17"
					onClick={() => getStartedForm.open()}
				>
					Get Started
				</PrimaryButton>

			</div>
		</FullWidthSection>
)};

export default HomeJoinAccenture;
