import { PrimaryButton } from "../../../../src/modules/_common/components/Button";
import { noop } from "lodash";
import { useRef } from "react";
import CoachBookingModal from "./CoachBookingModal";
import cc from "classcat";
import useAnonymousDataLayer from "../../../../src/_hooks/useAnonymousDataLayer";

const CoachActions = ({
  name,
  slug,
  callback = noop,
  className = "",
  allowBooking = false,
  whoIAm = null,
}) => (
  <div
    className={cc([
      "pt-4 pt-md-0 mt-auto",
      className,
      { "text-center": allowBooking },
    ])}
  >
    {whoIAm !== null && (
      <>
        <span className="d-block mt-md-2 mb-2 h3">Who I am</span>
        <div className="medium-text">{whoIAm}</div>
      </>
    )}
    {allowBooking && (
      <div className="mb-2">
        <PrimaryButton onClick={callback}>Book a Call</PrimaryButton>
      </div>
    )}
    <div className={cc(["mt-4", { "text-right": !allowBooking }])}>
      <a className="link" href={`/coaches/${slug}/`}>
        Read More about {name}
      </a>
    </div>
  </div>
);

const CoachGridItem = ({
  name,
  role,
  text,
  calendlyURLs,
  image,
  allowBooking,
  slug,
  whoIAm = null,
}) => {
  const modalRef = useRef(null);
  const { event } = useAnonymousDataLayer();

  const openModal = () => {
    if (modalRef.current) {
      event("Book a call", "Coaching", "Coaching Interactions");
      modalRef.current.open();
    }
  };

  return (
    <>
      <CoachBookingModal
        ref={modalRef}
        coach={{ name, role, image, calendlyURLs }}
      />
      <div className="row">
        <div className="col-auto coach-grid-item-image">{image}</div>
        <div className="col d-flex flex-column">
          <span className="h2 d-block">{name}</span>
          <span className="small-text text-italic d-block mt-1 mb-3">
            {role}
          </span>
          <div className="text-gray-dark text-italic medium-text">{text}</div>
          <CoachActions
            className={cc(["d-none d-md-block"])}
            name={name.split(" ")[0]}
            callback={openModal}
            allowBooking={allowBooking}
            whoIAm={whoIAm}
            slug={slug}
          />
        </div>
        <CoachActions
          className="d-block d-md-none col-12"
          name={name.split(" ")[0]}
          callback={openModal}
          allowBooking={allowBooking}
          whoIAm={whoIAm}
          slug={slug}
        />
      </div>
    </>
  );
};

export default CoachGridItem;
