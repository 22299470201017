import QuoteSVG from "@svgs/quote.svg";
import SVGImage from "../SVGImage";

const Review = ({ review, index, ...props }) => (
  <li className="review" data-index={index} {...props}>
    <div className="row mb-13 mb-md-8 justify-content-center justify-content-md-start">
      <div className="col-auto d-block d-md-none">
        <SVGImage>
          <QuoteSVG width="44" height="36" />
        </SVGImage>
      </div>
      <div className="col-auto col-md">
        <span className="d-block mb-1 md-md-2 review-author h3">
          {review.name}
        </span>
        <span className="d-block review-role text-gray-dark font-italic">
          {review.role}
        </span>
      </div>
    </div>
    <div className="row">
      <div className="col-auto d-none d-md-block">
        <SVGImage>
          <QuoteSVG width="44" height="36" />
        </SVGImage>
      </div>
      <div className="col">
        <span className="d-block h2 review-title mb-4 mb-md-6 text-center text-md-left">
          {review.title}
        </span>
        <div
          className="review-content big-text"
          dangerouslySetInnerHTML={{
            __html: review.text.replace(/\n/g, "<br />"),
          }}
        />
      </div>
    </div>
  </li>
);

export default Review;
