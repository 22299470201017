import Section from "../../../section/Section";
import useTypeForm from "../../../../hooks/useTypeForm";
import { Button } from "../../../../../../src/modules/_common/components/Button";
import personalisedImage from "@images/v2/home/personalised.png";
import helpedPeopleImage from "@images/v2/home/helped-people.png";
import Image from "../../../Image";
import useAnonymousDataLayer from "../../../../../../src/_hooks/useAnonymousDataLayer";

const circleItems = ["On-demand coaching", "Personalised programmes", "Highly qualified", "Affordable & scalable"];

const CareerDevelopmentSection = () => {
	const freeConsultationForm = useTypeForm("R6iu6OZO");
	const { event } = useAnonymousDataLayer();
	return (
		<Section className="career-development-section">
			<div className="text-center mx-auto" style={{ maxWidth: 800 }}>
				<p className="font-size-lg text-underline">The future of Career Development</p>
				<h2 className="pt-6 h1">Flourishing careers. Powered by technology.</h2>
			</div>
			<div className="d-flex flex-column flex-md-row circles-wrapper justify-content-sm-between">
				{circleItems.map((item, index) => (
					<div key={index} className="circle-item d-flex justify-content-center align-items-center text-center">
						<span>{item}</span>
					</div>
				))}
			</div>

			<div className="on-demand-coaching">
				<div className="d-flex justify-content-center text-gray-white pt-13 pt-md-18 pb-20 pb-md-18 my-md-6">
					<div className="text-center mx-auto" style={{ maxWidth: 440 }}>
						<p className="font-size-lg text-underline pb-8">On-demand coaching</p>
						<h2 className="pb-6 pb-md-4 px-12 px-md-0">From roadblocks to roads ahead</h2>
						<p className="font-size-xl px-5 px-md-0 pb-4 pb-md-0">
							For busy teams we’ve made coaching a low-touch book and chat experience. Available for 1:1 highly
							qualified, and highly engaged development.
						</p>
					</div>
				</div>
			</div>

			<div className="row mx-0 py-14 border-dark personalised-section justify-content-center justify-content-sm-start">
				<div className="col-12 col-sm-5 d-none d-sm-block pl-0">
					<Image src={personalisedImage} alt="Personalised" className="w-100" responsive={false} />
				</div>
				<div className="d-none d-sm-block col-sm-1"></div>
				<div className="col-12 col-sm-6 p-sm-0 text-center text-sm-left" style={{ maxWidth: 467 }}>
					<p className="font-size-lg text-underline pb-6 pb-sm-8 m-0">Personalised programmes</p>
					<h2 className="pb-10 pb-sm-10">Awaken your team's innate skills & talents</h2>
					<div className="d-block d-sm-none">
						<Image src={personalisedImage} alt="Personalised" className="w-100" responsive={false} />
					</div>

					<p className="font-size-xl m-0 pt-6 pt-sm-0">
						No person or team are alike. Our modular programme will plug into the unique challenges and goals of your
						organisation.
					</p>
					<Button
						onClick={() => {
							event("Book free consultation", "coaching", "Organisations interaction");
							freeConsultationForm.open();
						}}
						className="btn-cornered btn-white btn-hover-light-green mt-7 mt-sm-10"
					>
						Book a free consultation
					</Button>
				</div>
			</div>

			<div className="row mx-0 py-14 qualified-section justify-content-center justify-content-sm-between">
				<div className="col-12 col-sm-6 p-sm-0 text-center text-sm-left" style={{ maxWidth: 518 }}>
					<p className="font-size-lg text-underline pb-6 pb-sm-8 m-0">Qualified & Award Winning</p>
					<h2 className="pb-10 pb-sm-10">There’s no better teacher than experience.</h2>
					<div className="d-block d-sm-none">
						<Image src={helpedPeopleImage} alt="helped 18000 people" className="w-100" responsive={false} />
					</div>

					<p className="font-size-xl m-0 pt-6 pt-sm-0" style={{ maxWidth: 447 }}>
						We pride ourselves on practising what we preach. All of our Coaches are supported in their self and career
						development. They’re also all registered with the Career Development Institute and are qualified Mental
						Health First Aiders.
					</p>
				</div>
				<div className="d-none d-sm-block col-sm-1"></div>
				<div className="col-12 col-sm-5 d-none d-sm-block">
					<Image src={helpedPeopleImage} alt="helped 18000 people" className="w-100" responsive={false} />
				</div>
			</div>
		</Section>
	);
};

export default CareerDevelopmentSection;
