import { Children, cloneElement, createElement, memo, useMemo, useState } from "react";
import Icon from "./Icon";
import cc from "classcat";

const ListComponent = memo(({ componentType = "ul", children, ...props }) => {
	return createElement(componentType, props, children);
});

/**
 * An ordered list with 16px margin (by default) unless we expect large text then it's 24px
 * @param children
 * @param className
 * @param color
 * @param textColor
 * @param itemClass
 * @param extraGap
 * @param props
 * @return {JSX.Element}
 * @constructor
 */
const OrderedList = ({
	children,
	className = "",
	color = "purple-semi-dark",
	textColor = "gray-white",
	itemClass = "",
	extraGap = false,
	...props
}) => (
	<ListComponent
		{...{
			componentType: "ol",
			className: cc([
				`list list-ordered`,
				{ "flex-column": !className.includes("flex-row") && !className.includes("flex-column-reverse") },
				{ "list-large": extraGap },
				className,
			]),
			...props,
		}}
	>
		{Children.map(children, (child) =>
			cloneElement(child, {
				color: color,
				extraGap,
				textColor: textColor,
				type: "numeric",
				className: cc({
					[child.props.className + " " + itemClass]: child.props.className,
					[itemClass]: !child.props.className,
				}),
			})
		)}
	</ListComponent>
);

const UnorderedList = ({
	children,
	className = "",
	color = "violet-mid",
	itemClass = "",
	extraGap = false,
	...props
}) => (
	<ListComponent
		{...{
			componentType: "ul",
			className: cc([
				`list list-unordered`,
				{ "flex-column": !className.includes("flex-row") && !className.includes("flex-column-reverse") },
				{ "list-large": extraGap },
				className,
			]),
			...props,
		}}
	>
		{Children.map(children, (child) =>
			cloneElement(child, {
				color: color,
				extraGap,
				className: cc({
					[child.props.className + " " + itemClass]: child.props.className,
					[itemClass]: !child.props.className,
				}),
			})
		)}
	</ListComponent>
);

const Checklist = ({ children, className = "", color = "yellow-main", itemClass = "", extraGap = false, ...props }) => (
	<ListComponent
		{...{
			componentType: "ul",
			className: cc([
				`list list-checklist`,
				{ "flex-column": !className.includes("flex-row") && !className.includes("flex-column-reverse") },
				{ "list-large": extraGap },
				className,
			]),
			...props,
		}}
	>
		{Children.map(children, (child) => {
			return cloneElement(child, {
				color: color,
				type: "check",
				extraGap,
				className: cc({
					[child.props.className + " " + itemClass]: child.props.className,
					[itemClass]: !child.props.className,
				}),
			});
		})}
	</ListComponent>
);

const CustomIconList = ({
	children,
	className = "",
	color = "yellow-main",
	icon = "check",
	iconProps = {},
	itemClass = "",
	extraGap = false,
	...props
}) => (
	<ListComponent
		{...{
			componentType: "ul",
			className: cc([
				`list list-custom-icon`,
				{ "flex-column": !className.includes("flex-row") && !className.includes("flex-column-reverse") },
				{ "list-large": extraGap },
				className,
			]),
			...props,
		}}
	>
		{Children.map(children, (child) => {
			return cloneElement(child, {
				color: color,
				type: "icon",
				customIcon: icon,
				iconProps,
				extraGap,
				className: cc({
					[child.props.className + " " + itemClass]: child.props.className,
					[itemClass]: !child.props.className,
				}),
			});
		})}
	</ListComponent>
);

const ListItem = ({
	children,
	type = "bullet",
	customIcon = null,
	iconProps = {},
	color = "yellow-main",
	textColor = null,
	className = "",
	extraGap = false,
	...props
}) => {
	const [el, setEl] = useState(null);
	const index = useMemo(() => {
		if (!el) return 0;

		return [...el.parentNode.children].indexOf(el);
	}, [el]);

	const bulletType = useMemo(() => {
		switch (type) {
			case "check":
				return <Icon name="check" color={color} width={20} height={20} {...iconProps} />;
			case "icon":
				return <Icon name={customIcon} color={color} width={20} height={20} {...iconProps} />;
			case "numeric":
				return (
					<span
						className={`d-block rounded-circle bg-${color} text-${textColor} small font-weight-bold`}
						style={{
							width: 24,
							height: 24,
							lineHeight: "24px",
							textAlign: "center",
						}}
					>
						{index + 1}.
					</span>
				);
			default:
				return <span className={`d-block rounded-circle bg-${color}`} style={{ width: 8, height: 8, marginTop: 6 }} />;
		}
	}, [type, color, textColor, index, customIcon, iconProps]);

	return (
		<li
			className={cc(["d-flex list-item align-items-start flex-nowrap", { "list-item-large": extraGap }, className])}
			{...props}
			ref={(el) => {
				if (el) {
					setEl(el);
				}
			}}
		>
			<span className="pr-3" style={{ lineHeight: 0 }}>
				{bulletType}
			</span>
			<span>{children}</span>
		</li>
	);
};

const List = {
	Ordered: memo(OrderedList),
	Unordered: memo(UnorderedList),
	Item: memo(ListItem),
	Checklist: memo(Checklist),
	CustomIcon: memo(CustomIconList),
};

export default List;
