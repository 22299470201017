import { atom, atomFamily } from "recoil";

export const modalCurrentlyOpenState = atom({
	key: "modalCurrentlyOpen",
	default: null,
});

export const modalIsOpenState = atomFamily({
	key: "modalIsOpen",
	default: null,
});
