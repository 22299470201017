import Reviews from "../../components/Reviews";
import AverageCoachRating from "../../components/AverageCoachRating";
import HomeHeroAccenture from "../../components/_pages/home/HomeHeroAccenture";
import HomeIncredibleCoachingAccenture from "../../components/_pages/home/HomeIncredibleCoachingAccenture";
import HomeOurCareerBuildingPlatformAccenture
	from "../../components/_pages/home/featured-in/HomeOurCareerBuildingPlatformAccenture";
import HorizontalDivider from "../../components/HorizontalDivider";
import CoachesGrid from "../../components/CoachesGrid";
import OurPartnershipWithAccenture from "../../components/OurPartnershipWithAccenture";
import HomeProgrammeDetailsSectionAccenture from "../../components/_pages/home/HomeProgrammeDetailsSectionAccenture";
import HomeRecalibrateAccenture from "../../components/_pages/home/HomeRecalibrateAccenture";
import HomeCostAccenture from "../../components/_pages/home/HomeCostAccenture";
import HomeFreeConditionsAccenture from "../../components/_pages/home/HomeFreeConditionsAccenture";
import HomeJoinAccenture from "../../components/_pages/home/HomeJoinAccenture";
import LearnMoreNavigator from "../../components/_programmes/LearnMoreNavigator";
import useAnonymousDataLayer from "../../../../src/_hooks/useAnonymousDataLayer";

const HomepageDocumentAccenture = ({
																		 typeFormId,
																		 joinCriteria = [
																			 "You are based in the UK.",
																			 "You are between 24 and 65 years of age.",
																			 "You are receiving Universal Credit (or any legacy benefits)",
																			 "You can commit 6 hours per week to coaching and learning"
																		 ]
																	 }) => {
	const {event} = useAnonymousDataLayer();

	return (
		<>
			<LearnMoreNavigator
				domQuery=".cost-section"
				eventCallback={() => {
					event("Scroll from hero", "Homepage", "Homepage Interactions");
				}}
			/>
			<HomeHeroAccenture/>
			<HorizontalDivider dashed={true} className="my-0"/>
			<HomeProgrammeDetailsSectionAccenture/>
			<HorizontalDivider dashed={true} className="my-0"/>
			<HomeIncredibleCoachingAccenture/>
			<AverageCoachRating showButton={false}/>
			<HomeRecalibrateAccenture/>
			<HomeCostAccenture typeFormId={typeFormId}/>
			<HomeFreeConditionsAccenture joinCriteria={joinCriteria}/>
			<HomeJoinAccenture typeFormId={typeFormId}/>
			{/*<HomeOurCareerBuildingPlatformAccenture/>*/}
			{/*<HorizontalDivider dashed={true} className="my-0"/>*/}
			<Reviews readMore={true} className="pt-md-20 pt-10"/>
			<OurPartnershipWithAccenture/>
			<div className="py-3 py-md-7"/>
			<CoachesGrid/>
		</>
	)
};

export default HomepageDocumentAccenture;
