import Section from "../../../section/Section";
import useTypeForm from "../../../../hooks/useTypeForm";
import { Button } from "../../../../../../src/modules/_common/components/Button";
import affordableImage from "@images/v2/home/affordable.png";
import Image from "../../../Image";
import useAnonymousDataLayer from "../../../../../../src/_hooks/useAnonymousDataLayer";

const AffordableSection = () => {
	const freeConsultationForm = useTypeForm("R6iu6OZO");
	const { event } = useAnonymousDataLayer();
	return (
		<Section className="affordable-section">
			<div className="row mx-0 justify-content-center justify-content-sm-start">
				<div className="col-12 col-sm-5 d-none d-sm-block pl-0">
					<Image src={affordableImage} alt="Personalised" className="w-100" responsive={false} />
				</div>
				<div className="d-none d-sm-block col-sm-1"></div>
				<div className="col-12 col-sm-6 p-sm-0 text-center text-sm-left" style={{ maxWidth: 467 }}>
					<p className="font-size-lg text-underline pb-6 pb-sm-8 m-0">Affordable & Scalable</p>
					<h2 className="pb-4 pb-sm-6">Invest in your future leaders without breaking the bank</h2>

					<p className="font-size-xl m-0">
						Only pay for what you use. We’ve built a platform equipped to deal with organisations, big and small. If you
						need more coaching, we’ll scale it up and scale it back when you don’t.
					</p>

					<Button
						onClick={() => {
							event("Book free consultation", "coaching", "Organisations interaction");
							freeConsultationForm.open();
						}}
						className="btn-cornered btn-white btn-hover-light-green mt-7 mt-sm-10"
					>
						Book a free consultation
					</Button>
				</div>
			</div>
		</Section>
	);
};

export default AffordableSection;
