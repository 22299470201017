import Section from "../../section/Section";
import List from "../../../../../src/modules/_common/components/List";
import Title from "../../Title";
import Image from "../../Image";

import coachAppImage from "@images/homepage/coach-app.jpg";

const HomeIncredibleCoachingAccenture = () => (
	<>
		<Section className="pt-12 pb-8 pb-sm-0 incredible-coaching-section">
			<div className="d-flex flex-column flex-sm-row justify-content-center">
				<div className="d-block d-md-none mb-15">
					<Title className="text-center">
						The advice you want, when you need it
					</Title>
				</div>
				<div
					className="d-flex align-items-end"
					style={{lineHeight: 0}}
				>
					<Image src={coachAppImage} width={818}/>
				</div>
				<div className="mt-6 mt-sm-0" style={{maxWidth: 410}}>
					<Title className="text-left d-none d-md-block">
						The advice you want, when you need it
					</Title>
					<p className="text-gray-dark mt-4 big-text mb-0">
						Need help figuring out your next move, or finding a path to better-paid work?
					</p>
					<List.CustomIcon icon="favourite-careers" className="my-4 my-md-10">
						<List.Item className="pb-6">
							<h4 className="text-purple-semi-mid">
								On demand, qualified coaching.
							</h4>
							<p className="m-0 text-gray-dark">
								From our qualified career coaches for all your work-related challenges.
							</p>
						</List.Item>
						<List.Item className="pb-6">
							<h4 className="text-purple-semi-mid">
								Flexible, support when you need it.
							</h4>
							<p className="m-0 text-gray-dark">
								Meet your coach by webchat or video call, at a time that suits you.
							</p>
						</List.Item>
						<List.Item>
							<h4 className="text-purple-semi-mid">Personalised to you.</h4>
							<p className="m-0 text-gray-dark">
								Your dedicated coach will work with you to focus on your needs, goals and ambitions.
							</p>
						</List.Item>
						<List.Item className="pb-0">
							<h4 className="text-purple-semi-mid">Go at your own pace.</h4>
							<p className="m-0 text-gray-dark">
								All our programmes are self-paced to fit in with your commitments.
							</p>
						</List.Item>
					</List.CustomIcon>
				</div>
			</div>
		</Section>
	</>
);

export default HomeIncredibleCoachingAccenture;
